// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isEditingPI: false,
    isEditingAddr: false,
    isEditingID: false,
};

//-----------------------|| UI STATES REDUCER ||-----------------------//

const uiStatesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.START_EDITING_PI:
            return {
                ...state,
                isEditingPI: true,
            };
        case actionTypes.STOP_EDITING_PI:
            return {
                ...state,
                isEditingPI: false,
            };
        case actionTypes.START_EDITING_ADDR:
            return {
                ...state,
                isEditingAddr: true,
            };
        case actionTypes.STOP_EDITING_ADDR:
            return {
                ...state,
                isEditingAddr: false,
            };
        case actionTypes.START_EDITING_ID:
            return {
                ...state,
                isEditingID: true,
            };
        case actionTypes.STOP_EDITING_ID:
            return {
                ...state,
                isEditingID: false,
            };
        default:
            return state;
    }
};

export default uiStatesReducer;
