import useAPIError from "../hooks/useAPIError"
import { useOdooRequest } from "./odoo_request"


export function useOdooController(commonOptions) {
  const { OdooRequest } = useOdooRequest(commonOptions)
  const { addError } = useAPIError() // NOTE: this should be used instead of passing addError to each controller individually, but this is a big change and bot mobile and web should be updated and tested afterwards
  function CallController(url, body={}) {
    return OdooRequest(url, body, addError)
  }

  function GetReceiveCurrencies() {
    return CallController("/ClientApi/GetReceiveCurrencies")
  }

  function GetRates() {
    return CallController("/ClientApi/GetAllRates")
  }

  function GetExecutors() {
    return CallController("/ClientApi/GetExecutors")
  }

  function GetCompany() {
    return CallController("/ClientApi/GetCompany")
  }


  function GetSpecialRates() {
    return CallController("/ClientApi/GetAllSpecialRates")
  }

  function GetProfile() {
    return CallController("/ClientApi/GetProfile")
  }

  function GetCountries(){
    return CallController("/ClientApi/GetCountries")
  }

  function EditProfile(addError, body) {
    return CallController("/ClientApi/EditProfile", body)
  }

  function VerifyMobile(addError, body) {
    return CallController("/ClientApi/VerifyMobile", body)
  }

  function RequestMobileVerification(addError, body) {
    return CallController(
      "/ClientApi/RequestMobileVerification",
      body
    )
  }

  function GetTerms(addError, body) {
    return CallController("/ClientApi/GetTerms", body)
  }

  function GetVersion(addError, body) {
    return CallController("/ClientApi/GetVersion", body)
  }

  function GetResetPasswordToken(addError, body) {
    return CallController(
      "/ClientApi/GetResetPasswordToken",
      body
    )
  }

  function UploadAddressFiles(addError, body) {
    return CallController("/ClientApi/UploadAddressFiles", body)
  }

  function UploadIdFiles(addError, body) {
    return CallController("/ClientApi/UploadIdFiles", body)
  }

  function DeleteFile(body) {
    return CallController("/ClientApi/DeleteFile", body)
  }

  function UploadProfilePicture(addError, body) {
    return CallController(
      "/ClientApi/UploadProfilePicture",
      body
    )
  }

  function UploadFundProof(addError, body) {
    return CallController("/ClientApi/UploadFundProof", body)
  }

  function GetContact(addError, body) {
    return CallController("/ClientApi/GetContact", body)
  }

  function EditTransaction(body) {
    return CallController("/ClientApi/EditTransaction", body)
  }

  function UpdatePushNotificationToken(body) {
    return CallController(
      "/ClientApi/UpdatePushNotificationToken",
      body
    )
  }

  function UpdateUserInfo(body) {
    return CallController("/ClientApi/UpdateUserInfo", body)
  }

  function GetMetadata(){
    return CallController("/ClientApi/GetMetadata")
  }

  function GetSignupInfo(){
    return CallController("/ClientApi/GetSignupInfo")
  }

  function GetCheckoutId(body) {
    return CallController("/ClientApi/GetCheckoutId", body)
  }

  function GetTrueLayerPayment(body) {
    return CallController("/ClientApi/GetTrueLayerPayment", body)
  }

  function VerifyCode(body) {
    return CallController("/ClientApi/VerifyCode", body)
  }

  function GetNotifications(body) {
    return CallController("/ClientApi/GetNotifications", body)
  }

  return {
    CallController,
    GetReceiveCurrencies,
    GetRates,
    GetExecutors,
    GetCompany,
    GetSpecialRates,
    GetProfile,
    EditProfile,
    VerifyMobile,
    RequestMobileVerification,
    GetTerms,
    GetVersion,
    GetResetPasswordToken,
    UploadAddressFiles,
    UploadIdFiles,
    UploadProfilePicture,
    GetContact,
    DeleteFile,
    UpdatePushNotificationToken,
    EditTransaction,
    GetCountries,
    GetMetadata,
    GetSignupInfo,
    UpdateUserInfo,
    UploadFundProof,
    GetCheckoutId,
    GetTrueLayerPayment,
    VerifyCode,
    GetNotifications,
  }
}
