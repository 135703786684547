import { get_odoo_url } from "../constants"
import useAPIError from "../hooks/useAPIError"
import { actions } from "../providers/APIErrorProvider"
import useFetch from "../hooks/useFetch";
import { isNetworkError } from "../utils/commonComponentLogic";
//TODO: handle server is down issues: 1) nginx server is down, 2) odoo server is down

export const useOdooRequest = (commonOptions) => {
  const sentryCapture = commonOptions?.sentryCapture;
  const { addError, remote } = useAPIError()
  const { fetchImproved } = useFetch()
  const base_url = () => {
    // return get_odoo_url();
    return remote.odooUrl
  }
  function isResponseOk(response) {
    // console.debug('CALL::isResponseOk');
    let error_result = {
      ok: false
    }
    let success_result = {
      ok: true
    }
    let is_success = true
    if ("error" in response) {
      is_success = false
      if (response.error.code == 100) {
        // TODO: redirect to login page
        error_result.message = "Session expired, please login again" // translate me
        error_result.action = actions.sessionInvalid
      } else if (response.error.code == 200) {
        // Odoo Server Error
        if (response.error.data.name === "odoo.exceptions.AccessError") {
          error_result.message = "Access Denied" // translate me
          if (!response?.error?.data?.debug || !response?.error?.data?.debug?.split) {
            // TODO: remove all the splitting debug logs if the issue is fixed: https://ether-services-s1.sentry.io/issues/3923838340/?project=4504621956333568&query=is%3Aunresolved&referrer=issue-stream
            console.debug('splitting ${response?.error?.data?.debug}')
          }
          const findModelName = response.error.data.debug.split("")
          if (findModelName.length > 1) {
            error_result.message += findModelName[1].substring(
              0,
              findModelName[1].indexOf(")")
            )
          }
        }
      } else if (response.error.code == 404) {
        // TODO: redirect to login page
        error_result.message = "Make sure you are logged in!" // translate me
        error_result.action = actions.sessionInvalid
      }
    }
    // checking for custom api errors
    if (response && response.result && response.result.success === false) {
      // console.debug('@@@ about to flag error in isResponseOk');
      is_success = false
      error_result.errorCode = response.result.error
      if (response.result.message) {
        error_result.message = response.result.message // translate me
      } else {
        // TODO: make sure this general error message is ok
        error_result.message = "Something went wrong!" // translate me
      }
    }
    if (is_success) {
      return success_result
    } else {
      if (!error_result.message) {
        error_result.message = "Something went wrong!" // translate me
      }
      return error_result
    }
  }

  async function OdooRequest(url, requestBody, addErrorOld, options) {
    url = base_url() + url
    options = options ? options : {}
    console.debug(`@ url is "${url}"`)
    const fullRequestBody = {
      jsonrpc: "2.0",
      method: "call",
      params: requestBody
    }
    let response = null
    let json = null
    try {
      response = await fetchImproved(url, {
        method: "POST", // don't use GET, AFAIK: because odoo will not allow json with get
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(fullRequestBody),
        timeout: options.timeout,
      })
      json = await response.json()
    } catch (error) {
      if (isNetworkError(error)) {
        addError('Please check your internet connection', null, null, { type: "network", header: "Connection Error", caller: "useOdooRequest.OdooRequest" })
      }
      sentryCapture && sentryCapture(error, {
        extraData: {
          url,
          model: fullRequestBody?.params?.model
        }
      });
      throw error;
    }
    const responseToLogLength = 50
    var responseToLog = String(json).substring(0, responseToLogLength)
    if (String(json).length > responseToLogLength) {
      responseToLog += "..."
    }
    if (options.log) {
      console.log("#response")
      console.log(json)
    }
    const response_status = isResponseOk(json)
    if (!response_status.ok) {
      addError(
        response_status.message,
        response_status.action,
        response_status.errorCode,
        { source: url, requestBody }
      )
    }
    return json
  }

  async function OdooRequestWrapper(url, requestBody, addErrorOld, options) {
    options = options ? options : {}
    let error_result = {
      ok: false
    }
    let success_result = {
      ok: true,
      result: {}
    }
    const response = await OdooRequest(url, requestBody, addError)
    if (options.logOutput) {
      console.log(response)
    }
    if (response && response.result) {
      if (response.result.success || response.result.uid) {
        success_result.result = response.result
        return success_result
      } else if (response.result.success === false) {
        addError(response.result.message)
        return error_result
      }
    }
    addError("Something went wrong!") // translate me
    return error_result
  }

  return {
    OdooRequest,
    base_url,
    OdooRequestWrapper
  }
}
