const config = {
    // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
    // like '/berry-material-react/react/default'
    basename: '',
    defaultPath: '/transfers',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    API_SERVER: 'http://localhost:8008',
    presetColor: 'theme1', // default, theme1, theme2 to theme6 available
    odooDateFormat: 'YYYY-MM-DD',
    SUDAN_COUNTRY_ID: 195,
};

export default config;
