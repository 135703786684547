import React from "react"
import moment from "moment"
import { currencyFormat } from "./parseData"

export function getObjectById(objectList, targetId) {
  // will return an empty object if didn't find the ID
  if (objectList) {
    for (let i = 0; i < objectList.length; i++) {
      if (objectList[i].id === targetId) {
        return objectList[i]
      }
    }
  }
  return {}
}

export function trimTime(datetime) {
  // value is like "2020-07-04 14:41:26"
  if (!datetime) {
    return null
  }
  if (!datetime || !datetime.split) {
    console.debug('splitting ${datetime} failed in trimTime')
  }
  return datetime.split(" ")[0]
}

export function displayDate(datetime) {
  let formattedDate = trimTime(datetime)
  try {
    formattedDate = moment(formattedDate, "YYYY-MM-DD").format("DD MMM YYYY")
  } catch { }
  return formattedDate
}

export const fLCapital = (s) => {
  if (s === "mbok") {
    return "mBok"
  }
  return s ? s.replace(/./, (c) => c.toUpperCase()) : ""
}

export function filterSingleton(records, compareFunc) {
  for (let i = 0; i < records.length; i++) {
    if (compareFunc(records[i])) {
      return records[i]
    }
  }
  return {}
}

export const useYupValidationResolver = (validationSchema) =>
  // TODO: fix the issue with the resolver, for some reason when calling trigger function from the useForm of react-hook-form, the errors is not set!!! maybe search stackoverflow for "errors are not set when calling trigger"
  React.useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
    },
    [validationSchema]
  )

export function isBank(picking_method) {
  if (["bank", "mbok"].includes(picking_method)) {
    return true
  }
  return false
}

export const delay = (ms) => new Promise((res) => setTimeout(res, ms))

export const TransStates = {
  draft: 'draft',
  customer_paid: 'customer_paid',
  paid: 'paid',
  approved: 'approved',
  confirm: 'confirm',
  received: 'received',
  cancel: 'cancel',
}

// sauce: https://stackoverflow.com/a/9436948/3557761
export const isString = input => (typeof input === 'string' || input instanceof String)

export const checkDomain = (object, domain) => {
  let satisfyDomain = []
  for (let j = 0; j < domain.length; j++) {
    const domainItem = domain[j]
    const leftValue = object[domainItem[0]]
    const operation = domainItem[1]
    const rightValue = domainItem[2]
    if (operation === '=') {
      satisfyDomain.push(leftValue === rightValue)
    }
    if (operation === '<') {
      satisfyDomain.push(leftValue < rightValue)
    }
    if (operation === '<=') {
      satisfyDomain.push(leftValue <= rightValue)
    }
    if (operation === '>') {
      satisfyDomain.push(leftValue > rightValue)
    }
    if (operation === '>=') {
      satisfyDomain.push(leftValue >= rightValue)
    }
    if (operation === '!=') {
      satisfyDomain.push(leftValue !== rightValue)
    }
  }

  if (satisfyDomain.length && satisfyDomain.every(x => x === true)) {
    return true
  }
  return false
}

export const isNetworkError = error => false  // error?.message?.includes && error?.message?.includes('Network request failed')

export const getTransCommission = trans => {
  let commission = ''
  if(!trans.same_currency && trans.foreign_commission){
    commission = currencyFormat(trans.foreign_commission)
  }else if (trans.same_currency && trans.apply_commission && trans.commission){
    commission = currencyFormat(trans.commission)
  }
  if(commission){
    commission = `${commission} ${trans.send_currency_id?.length ? trans.send_currency_id[1] : ''}`
  }
  return commission
}