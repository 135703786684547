import { selectBanks, selectPurposeOfTransaction, selectRelationToReceiver, selectFundsSource } from "./redux";

export const FieldTypes = {
    m2o: 'm2o',
    char: 'char',  // string value
    boolean: 'boolean',
    integer: 'integer',
}

export const Transaction = {
    bank_id: {
        name: "bank_id",
        displayName: "bank_idName",
        label: "Bank",
        selector: selectBanks,
    },
    purpose_id: {
        name: "purpose_id",
        displayName: "purpose_idName",
        label: "Purpose of Transaction",
        selector: selectPurposeOfTransaction,
        required: true,
        type: FieldTypes.m2o,
    },
    fund_source_id: {
        name: "fund_source_id",
        displayName: "fund_source_idName",
        label: "Source of Funds",
        selector: selectFundsSource,
        required: true,
        type: FieldTypes.m2o,
    },
    relation_id: {
        name: "relation_id",
        displayName: "relation_idName",
        label: "Relation to Receiver",
        selector: selectRelationToReceiver,
        required: true,
        type: FieldTypes.m2o,
    },
}