import { combineReducers } from "redux"
import { fileTypes } from "../utils/config";
import { purposeOfTransactionReducer, relationToReceiverReducer, fundsSourceReducer, fundLinesReducer, newTransReducer, executorsReducer, companyReducer, signupInfoReducer, notificationsReducer } from "./slices";
import { get_db_name, get_odoo_url } from "../constants";
export const actions = {
  clear_time: 'CLEAR_TIME',
  update_time: 'UPDATE_TIME',
  update_remote: 'UPDATE_REMOTE',
  update_metadata: 'UPDATE_METADATA',
  update_single_trans: 'UPDATE_SINGLE_TRANS',
}
// Actions
export const updateProfileAction = (profile) => ({
  type: "UPDATE_PROFILE",
  profile
})
export const updateCitiesAction = (cities) => ({
  type: "UPDATE_CITIES",
  cities
})
export const updateTransAction = (trans) => ({
  type: "UPDATE_TRANS",
  trans
})
export const updateSingleTransAction = (trans) => ({
  type: actions.update_single_trans,
  trans
})
export const addTransAction = (newTrans) => ({
  type: "ADD_TRANS",
  newTrans
})
export const updateReceiversAction = (receivers) => ({
  type: "UPDATE_RECEIVERS",
  receivers
})
export const updateLoginInfoAction = (loginInfo) => ({
  type: "UPDATE_LOGIN_INFO",
  loginInfo
})
export const updateCountriesAction = (countries) => ({
  type: "UPDATE_COUNTRIES",
  countries
})
export const updateSendAccountsAction = (sendAccounts) => ({
  type: "UPDATE_SEND_ACCOUNTS",
  sendAccounts
})
export const updateBranchesAction = (branches) => ({
  type: "UPDATE_BRANCHES",
  branches
})
export const updateBanksAction = (banks) => ({
  type: "UPDATE_BANKS",
  banks
})
export const updateTermsAction = (terms) => ({
  type: "UPDATE_TERMS",
  terms
})
export const updateVersionAction = (version) => ({
  // this should be obsolete after making sure all clients upgraded to use newVersion
  type: "UPDATE_VERSION",
  version
})
export const updateRemoteFilesAction = (remoteFiles) => ({
  type: "UPDATE_REMOTE_FILES",
  remoteFiles
})
export const deleteLocalFileAction = (id) => ({
  type: "DELETE_LOCAL_FILE",
  id
})
export const addLocalFileAction = (newFile) => ({
  type: "ADD_LOCAL_FILE",
  newFile
})
export const clearLocalFileAction = () => ({
  type: "CLEAR_LOCAL_FILES"
})
export const updateLocalFileAction = (remoteFiles) => ({
  type: "UPDATE_LOCAL_FILES",
  remoteFiles
})
export const updateNewVersionAction = (version) => ({
  type: "UPDATE_NEW_VERSION",
  version
})
export const updateContact = (contact) => ({
  type: "UPDATE_CONTACT",
  contact
})
export const clearTime = () => ({
  type: actions.clear_time,
})
export const updateTimeItem = (timeItem) => ({
  type: actions.update_time,
  timeItem
})
export const updateRemote = (remote) => ({
  type: actions.update_remote,
  remote
})
export const updateMetadataAction = (metadata) => ({
  type: actions.update_metadata,
  metadata
})

// Reducers
const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_PROFILE":
      return action.profile
    default:
      return state
  }
}
const citiesReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_CITIES":
      return action.cities
    default:
      return state
  }
}
const transReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_TRANS":
      return action.trans
    case actions.update_single_trans:
      // TODO: test that this actually works
      const others = state.filter(x => x.id !== action.trans.id)
      let res = [...others]
      res.push(action.trans)
      return res
    case "ADD_TRANS":
      // adds or update a single trans to the list
      let existingTrans = state ? state : [] // Initial State
      let index = existingTrans.findIndex(
        (item) => item.id === action.newTrans.id
      )
      if (index === -1) {
        index = 0
      }
      const existingWithoutNewTrans = existingTrans.filter(
        (item) => item.id !== action.newTrans.id
      )
      let newList = [...existingWithoutNewTrans]
      newList.splice(index, 0, action.newTrans)
      return newList
    default:
      return state
  }
}
const receiversReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_RECEIVERS":
      return action.receivers
    default:
      return state
  }
}
const countriesReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_COUNTRIES":
      return action.countries
    default:
      return state
  }
}
const loginInfoReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_LOGIN_INFO":
      return action.loginInfo
    default:
      return state
  }
}
const sendAccountsReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_SEND_ACCOUNTS":
      return action.sendAccounts
    default:
      return state
  }
}
const branchesReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_BRANCHES":
      return action.branches
    default:
      return state
  }
}
const banksReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_BANKS":
      return action.banks
    default:
      return state
  }
}
const termsReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_TERMS":
      return action.terms
    default:
      return state
  }
}
const versionReducer = (state = [], action) => {
  // this should be obsolete after making sure all clients upgraded to use newVersion
  switch (action.type) {
    case "UPDATE_VERSION":
      return action.version
    default:
      return state
  }
}
const remoteFilesReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_REMOTE_FILES":
      return action.remoteFiles
    default:
      return state
  }
}
const newVersionReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_NEW_VERSION":
      return action.version
    default:
      return state
  }
}
const localFilesReducer = (state = [], action) => {
  let localFiles = state ? state : [] // Initial State
  switch (action.type) {
    case "ADD_LOCAL_FILE":
      const localFilesWithoutNewFile = localFiles.filter(
        (item) => item.id !== action.newFile.id
      ) // this is necessary to prevent duplication, sometimes the file already exists!
      return [...localFilesWithoutNewFile, action.newFile]
    case "UPDATE_LOCAL_FILES":
      // this should update local file info based on newly fetched remote file status (mainly called inside the pullRemoteFileStatus)
      let res = []
      localFiles.map((item) => {
        let updateProps = action.remoteFiles.find((el) => el.id === item.id)
        if (updateProps === undefined) {
          updateProps = {}
        }
        res.push({ ...item, ...updateProps })
      })
      return res
    case "DELETE_LOCAL_FILE":
      return localFiles.filter(({ id }) => id !== action.id)
    case "CLEAR_LOCAL_FILES":
      return []
    default:
      return localFiles
  }
}
const contactReducer = (state = [], action) => {
  switch (action.type) {
    case "UPDATE_CONTACT":
      return action.contact
    default:
      return state
  }
}
const updateTimeReducer = (state = [], action) => {
  let allUpdateTimes = state ? state : {} // Initial State
  switch (action.type) {
    case actions.clear_time:
      return {}
    case actions.update_time:
      let res = {}
      for (const [key, value] of Object.entries(allUpdateTimes)) {
        if(item => item.name !== action.timeItem.name){
          res[key] = value;
        }
      }
      res[action.timeItem.name] = action.timeItem.value
      return res
    default:
      return state
  }
}
const updateRemoteReducer = (state = {}, action) => {
  let remote = {  // NOTE: this is not used yet !!! the one used is the context one!
    remoteDB: get_db_name(), // why use different names than the ones in the context? to make migration to using redux easier
    remoteUrl: get_odoo_url()
  }
  // NOTE: this is still unfinished, first set the initial state then use this state in api hooks
  switch (action.type) {
    case actions.update_remote:
      return action.remote
    default:
      return state
  }
}
const updateMetadataReducer = (state = [], action) => {
  switch (action.type) {
    case actions.update_metadata:
      return action.metadata
    default:
      return state
  }
}

export const rootReducer = combineReducers({
  profile: profileReducer,
  cities: citiesReducer,
  trans: transReducer,
  receivers: receiversReducer,
  countries: countriesReducer,
  loginInfo: loginInfoReducer,
  sendAccounts: sendAccountsReducer,
  branches: branchesReducer,
  banks: banksReducer,
  terms: termsReducer,
  version: versionReducer,
  remoteFiles: remoteFilesReducer,
  localFiles: localFilesReducer,
  newVersion: newVersionReducer,
  contact: contactReducer,
  lastUpdate: updateTimeReducer,
  remote: updateRemoteReducer,
  metadata: updateMetadataReducer,
  purposeOfTransaction: purposeOfTransactionReducer,
  relationToReceiver: relationToReceiverReducer,
  fundsSource: fundsSourceReducer,
  fundLines: fundLinesReducer,
  newTrans: newTransReducer,
  executors: executorsReducer,
  company: companyReducer,
  signupInfo: signupInfoReducer,
  notifications: notificationsReducer,
})

const filterLocalFiles = (localFiles, fileFor) => 
  localFiles ? localFiles.filter((item) => item.fileFor === fileFor) : []

export const selectFilesByType = (state, fileFor) => filterLocalFiles(state.odoo.localFiles, fileFor)

export const selectIdFiles = state => filterLocalFiles(state.odoo.localFiles, fileTypes.id)

export const selectAddressFiles = state => filterLocalFiles(state.odoo.localFiles, fileTypes.address)

export const selectRateFiles = state => filterLocalFiles(state.odoo.localFiles, fileTypes.rate_ad)

export const selectProfile = state =>  state.odoo.profile ? state.odoo.profile : {}

export const selectLoginInfo = state => state.odoo.loginInfo ? state.odoo.loginInfo : {}

export const selectMetadata = state => state.odoo.metadata ? state.odoo.metadata : {}

export const selectPurposeOfTransaction = state =>  state.odoo.purposeOfTransaction ? state.odoo.purposeOfTransaction : []
export const selectRelationToReceiver = state =>  state.odoo.relationToReceiver ? state.odoo.relationToReceiver : []
export const selectFundsSource = state =>  state.odoo.fundsSource ? state.odoo.fundsSource : []

export const selectBanks = state =>  state.odoo.banks ? state.odoo.banks : []

export const selectFundLines = state =>  state.odoo.fundLines ? state.odoo.fundLines : []
export const selectNewTrans = state =>  state.odoo.newTrans ? state.odoo.newTrans : {}
export const selectExecutors = state =>  state.odoo.executors ? state.odoo.executors : []
export const selectCountries = state =>  state.odoo.countries ? state.odoo.countries : []
export const selectCompany = state =>  state.odoo.company ? state.odoo.company : {}
export const selectReceivers = state =>  state.odoo.receivers ? state.odoo.receivers : []
export const selectSignupInfo = state => state.odoo.signupInfo ? state.odoo.signupInfo : {}
export const selectTrans = state => state.odoo.trans ? state.odoo.trans : []
export const selectSendAccounts = state => state.odoo.sendAccounts ? state.odoo.sendAccounts : []
export const selectNotifications = state => state.odoo.notifications ? state.odoo.notifications : []
