import { dashboard } from './dashboard';
import { profile } from './profile';
import { legal } from './legal';
import { other } from './other';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, profile, legal]
};

export default menuItems;
