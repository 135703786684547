import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import GuestGuard from './../utils/route-guard/GuestGuard';
import MinimalLayout from './../layout/MinimalLayout';
import NavMotion from './../layout/NavMotion';
import Loadable from '../ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login')));
const AuthRegister = Loadable(lazy(() => import('../views/pages/authentication/register')));
const AuthForgotPassword = Loadable(lazy(() => import('../views/pages/authentication/forgot-password')));
const Terms = Loadable(lazy(() => import('../views/legal-terms')));
const OnlineTerms = Loadable(lazy(() => import('../views/legal-online-terms')));

//-----------------------|| AUTH ROUTING ||-----------------------//

const LoginRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/login', '/register', '/forgot-password', '/terms', '/online-terms']}>
            {/* NOTE: you can not use the same route in both LoginRoutes and MainRoutes, because it will conflict in the GuestGuard, logged in users won't be able to open these urls.  */}
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <NavMotion>
                        <GuestGuard>
                            <Route path="/login" component={AuthLogin} />
                            <Route path="/register" component={AuthRegister} />
                            <Route path="/forgot-password" component={AuthForgotPassword} />

                            <Route path="/terms" component={Terms} />
                            <Route path="/online-terms" component={OnlineTerms} />
                        </GuestGuard>
                    </NavMotion>
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default LoginRoutes;
