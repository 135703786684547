// assets
import { IconDashboard, IconDeviceAnalytics, IconUsers, IconReportMoney, IconExchange, IconSquarePlus } from '@tabler/icons';

// constant
const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,
    IconUsers,
    IconReportMoney,
    IconRates: IconExchange,
    IconNewTransfer: IconSquarePlus,
};

//-----------------------|| DASHBOARD MENU ITEMS ||-----------------------//

export const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'transfers',
            title: 'Transfer History',
            type: 'item',
            url: '/transfers',
            icon: icons['IconReportMoney'],
            breadcrumbs: true
        },
        {
            id: 'new_transfer',
            title: 'New Transfer',
            type: 'item',
            url: '/start-transfer',
            icon: icons['IconNewTransfer'],
            breadcrumbs: true
        },
        {
            id: 'receivers',
            title: 'Receivers',
            type: 'item',
            url: '/receivers',
            icon: icons['IconUsers'],
            breadcrumbs: true
        },
        {
            id: 'rates',
            title: 'Rates',
            type: 'item',
            url: '/rates',
            icon: icons['IconRates'],
            breadcrumbs: true
        },
    ]
};
