import { useOdooRequest } from "./odoo_request"
import { useOdooController } from "./controller"
import { get_db_name } from "../constants"
import useAPIError from "../hooks/useAPIError"
import useFetch from "../hooks/useFetch";
import { isNetworkError } from "../utils/commonComponentLogic";

export function useAuth(commonOptions) {
  const sentryCapture = commonOptions?.sentryCapture;
  const { addError, remote } = useAPIError()
  const { OdooRequest } = useOdooRequest(commonOptions)
  const { fetchImproved } = useFetch()

  function getDefaultDatabase() {
    // return get_db_name();
    return remote.databaseName
  }
  function getDefaultLogin() {
    // return 'portal1@example.com';
    return ""
  }
  function getDefaultPassword() {
    return ""
    // return Platform.OS === 'web' ? '12345678a' : '';
  }
  function base_url() {
    return remote.odooUrl
  }

  async function Auth(addErrorOld, login = null, password = null, db = null) {
    let url = "/web/session/authenticate"
    db = db ? db : getDefaultDatabase()
    login = login ? login : getDefaultLogin()
    password = password ? password : getDefaultPassword()
    console.debug("@@ logging in to ", base_url(), " for db ", db)
    let requestBody = {
      db: db,
      login: login,
      password: password
    }
    const authResult = await OdooRequest(url, requestBody, addError)
    if (authResult === undefined) {
      addError("Unable to login") // translate me
      return
    }
    const result = authResult.result
    if (!("uid" in result)) {
      addError("Something went wrong") // translate me
    } else if (!result.uid) {
      addError(true)
      addError("Wrong username or password") // translate me
    } else {
      return result
    }
  }

  async function SignUp(
    addErrorOld,
    name = null,
    login = null,
    password = null,
    db = null
  ) {
    let url = "/ClientApi/SignUp"
    db = db ? db : getDefaultDatabase()
    login = login ? login : getDefaultLogin()
    password = password ? password : getDefaultPassword()
    let requestBody = {
      db: db,
      name: name,
      login: login,
      password: password
    }
    const { CallController } = useOdooController()
    const authResult = await CallController(url, requestBody)
    if (authResult === undefined) {
      addError("Unable to sign up") // translate me
      return
    }
    const result = authResult.result
    if (!("uid" in result)) {
      addError("Something went wrong") // translate me
    } else if (!result.uid) {
      addError(true)
      addError("Wrong username or password") // translate me
    } else {
      return result.uid
    }
  }

  async function SignOut() {
    const url = base_url() + "/web/session/logout"
    try {
      await fetchImproved(url) // TODO: prevent automatically redirecting to /web, e.g: ignore the 303
    } catch (error){
      if(isNetworkError(error)){
        addError('Please check your internet connection', null, null, { type: "network", header: "Connection Error", caller: 'useAuth.SignOut'})
      }
      sentryCapture && sentryCapture(error, {
        extraData: {
          url,
        }
      })
    }
  }

  async function CreateDbSession() {
    // TODO: prevent automatically redirecting to /web, e.g: ignore the redirect
    const url =  `${base_url()}/web/?db=${getDefaultDatabase()}`
    try {
      await fetchImproved(url)
    } catch (error){
      if(isNetworkError(error)){
        addError('Please check your internet connection', null, null, { type: "network", header: "Connection Error", caller: 'useAuth.CreateDbSession'})
      }
      sentryCapture && sentryCapture(error, {
        extraData: {
          url,
        }
      })
    }
  }

  async function GetResetPasswordCSRF(addErrorOld, token, db = null) {
    db = db ? db : getDefaultDatabase()
    const fullUrl = `${base_url()}/web/reset_password?db=${db}&token=${token}`
    const urlToLog = fullUrl.replace(token, '********')

    try{
      const response = await fetchImproved(fullUrl)
      if (response === undefined) {
        addError("Unable to connect to the server") // translate me
        return
      }
      const body = await response.text()
      let start = body.indexOf('name="csrf_token"') // is there a better way to get the toke?
      start = body.indexOf('value="', start) + 7
      const end = body.indexOf('"', start)
      return body.substring(start, end)
    }catch(error){
      if(isNetworkError(error)){
        addError('Please check your internet connection', null, null, { type: "network", header: "Connection Error", caller: 'useAuth.GetResetPasswordCSRF'})
      }
      sentryCapture && sentryCapture(error, {
        extraData: {
          url: urlToLog,
        }
      })
    }
  }

  async function ResetPassword(
    addErrorOld,
    login,
    name,
    password,
    confirmPassword,
    token,
    csrfToken,
    db = null
  ) {
    db = db ? db : getDefaultDatabase()
    csrfToken = await GetResetPasswordCSRF(addError, token, db) // TODO: move this to feature page
    let url = `/ClientApi/ResetPassword`
    const requestBody = {
      csrf_token: csrfToken,
      login: login,
      name: name,
      password: password,
      confirm_password: confirmPassword,
      token: token
    }
    const response = await OdooRequest(url, requestBody, addError)
    if (response === undefined) {
      addError("Unable to change password") // translate me
      return
    }
    const result = response.result
    return result
  }

  async function ForgotPassword(addErrorOld, login, contact, db = null) {
    db = db ? db : getDefaultDatabase()
    let url = `/ClientApi/ForgotPassword`
    const requestBody = {
      login: login,
      contact: contact
    }
    const response = await OdooRequest(url, requestBody, addError)
    if (response === undefined) {
      addError("Operation Failed") // translate me
      return
    }
    const result = response.result
    return result
  }

  return {
    Auth,
    getDefaultDatabase,
    getDefaultLogin,
    getDefaultPassword,
    SignUp,
    SignOut,
    CreateDbSession,
    GetResetPasswordCSRF,
    ResetPassword,
    ForgotPassword
  }
}
