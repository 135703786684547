// import { ModalService, Layout, Text } from '@ui-kitten/components';
import React, { useState, useCallback } from "react"
import { INVALID_MOBILE_VERIFICATION } from "../../api/remote_errors"
import { get_db_name, get_odoo_url } from "../../constants"

export const actions = {
  sessionInvalid: "session_invalid",
  updateRequired: "update_required"
}

const defaultRemote = {  // NOTE: this is actually used !!!
  databaseName: get_db_name(),
  odooUrl: get_odoo_url()
}

const defaultDebugging = {
  enabled: false,
}

export const APIErrorContext = React.createContext({
  // NOTE: after adding props here, you must also add then in the useAPIError hook
  error: null,
  addError: (message, action, errorCode, options) => {},
  removeError: () => {},
  remote: defaultRemote,  // NOTE: this is not used !!!
  changeRemote: (newRemote) => {},  // NOTE: this is not used !!!
  debugging: defaultDebugging,
  setDebugging: value => {},
})

export default function APIErrorProvider({ children }) {
  const [error, setError] = useState(null)
  const [debugging, setDebugging] = useState(defaultDebugging)
  const [remote, setRemote] = useState(defaultRemote)

  const removeError = () => {
    setError(null)
  }

  const addError = (message, action, errorCode, options) => {
    console.debug(
      `### we are in the beam addError() with ${message} ::and:: action is ${action} ::and:: errorCode is ${errorCode} ::and:: options is => ${JSON.stringify(options)}`
    )
    if (errorCode === INVALID_MOBILE_VERIFICATION) {
      // mobile verification error doesn't need a modal
      return
    }
    if (!error) {
      // TODO: handle multiple errors, better. IF NEEDED.
      setError({ message: message + '', action, errorCode, options })
    }
  }

  const changeRemote = (newRemote) => setRemote(newRemote)

  const contextValue = {
    error,
    addError: useCallback(
      (message, action, errorCode, options) =>
        addError(message, action, errorCode, options),
      []
    ),
    removeError: useCallback(() => removeError(), []),
    remote,
    changeRemote,
    debugging,
    setDebugging: useCallback(options => setDebugging(options), []),
  }

  return (
    <APIErrorContext.Provider value={contextValue}>
      {children}
    </APIErrorContext.Provider>
  )
}
