import { createSlice } from '@reduxjs/toolkit'

/* NOTE: Checklist for adding a new slice?
1. In this file:
  a. Use the generateSlice to create the slice object
  b. Export the set action and reducer
2. In the file where the root reducer is defined:
  a. Add the new reducer to the combineReducers parameters
  b. If necessary, add the pull method for this new slice's data
*/

const generateSlice = ({name, initialState}) => createSlice({
  name,
  initialState,
  reducers: {
    set: (state, action) => action.payload,
    update: (state, action) => {return {...state, ...action.payload } },
    updateSingle: (state, action) => {
      // TODO: test this function
      const others = state.filter(x => x.id !== action.payload.id)
      let res = [...others]
      res.push(action.payload)
      return res
    },
  },
})

const purposeOfTransactionSlice = generateSlice({name: 'purposeOfTransaction', initialState: []})
const relationToReceiverSlice = generateSlice({name: 'relationToReceiver', initialState: []})
const fundsSourceSlice = generateSlice({name: 'fundsSource', initialState: []})
const fundLinesSlice = generateSlice({name: 'fundLines', initialState: []})
const newTransSlice = generateSlice({name: 'newTrans', initialState: {}})
const executorsSlice = generateSlice({name: 'executors', initialState: []})
const companySlice = generateSlice({name: 'company', initialState: {}})
const signupInfoSlice = generateSlice({name: 'singupInfo', initialState: {}})
const notificationsSlice = generateSlice({name: 'notifications', initialState: {}})

export const { set: setPurpose } = purposeOfTransactionSlice.actions
export const { set: setRelationToReceivers } = relationToReceiverSlice.actions
export const { set: setFundsSource } = fundsSourceSlice.actions
export const { set: setFundLines } = fundLinesSlice.actions
export const { set: setNewTrans, update: updateNewTrans } = newTransSlice.actions
export const { set: setExecutors } = executorsSlice.actions
export const { set: setCompany } = companySlice.actions
export const { set: setSignupInfo } = signupInfoSlice.actions
export const { set: setNotifications } = notificationsSlice.actions

export const purposeOfTransactionReducer = purposeOfTransactionSlice.reducer
export const relationToReceiverReducer = relationToReceiverSlice.reducer
export const fundsSourceReducer = fundsSourceSlice.reducer
export const fundLinesReducer = fundLinesSlice.reducer
export const newTransReducer = newTransSlice.reducer
export const executorsReducer = executorsSlice.reducer
export const companyReducer = companySlice.reducer
export const signupInfoReducer = signupInfoSlice.reducer
export const notificationsReducer = notificationsSlice.reducer
