import { Transaction } from "../storage/models"
import { useOdooRequest } from "./odoo_request"

export const fieldsTrans = [
  "id",
  "ref",
  "sender_name",
  "receiver_name",
  "sum_to_receive",
  "currency_name",
  "currency_id",
  "state",
  "sum_to_send",
  "rate",
  "payment_method_type",
  "picking_method_type",
  "create_date",
  "send_bank_account_id",
  "receiver_country_id",
  "receiver_id",
  "bank_branch",
  "bank_details",
  Transaction.purpose_id.name,
  Transaction.fund_source_id.name,
  Transaction.relation_id.name,
  'send_currency_id',
  'can_pay_online',
  'client_warning',
  'executor_company_id',
  'apply_commission',
  'commission',
  'foreign_commission',
  'sum_to_pay_real',
  'same_currency',
  'online_checkout_id',
  'online_payment',
]
export const fieldsReceivers = [
  "id",
  "name",
  "name_ar",
  "mobile",
  "mobile2",
  "city_id",
  "write_date",
  "editable",
  "bank_id",
  "bank_branch",
  "bank_details",
  "country_id",
  "relation_id",
  "country_code",
]
export const fieldsDefault = ["id", "name"]
export const sendAccountFields = [
  "id",
  "name",
  "nick_name",
  "bank_name",
  "sort_code",
  "account_number",
  "type",
  "counter"
]
export const branchFields = ["id", "name", "postcode", "city"]
export const cityFields = ["id", "name", "country_id"]
export const bankFields = ["id", "name", "country_id"]
export const fundLineFields = ["id", "amount", "trans_amount", "filenames", "reject_reason", "create_date", "files_count", "state"]
// TODO: allow portal user to read cities

export const useOdooRead = (commonOptions) => {
  const { OdooRequest } = useOdooRequest(commonOptions)
  const getFieldsList = (obj) => {
    let fieldsList = ["id", "name"]
    if (obj === "ether.trans") {
      fieldsList = fieldsTrans
    } else if (obj === "ether.receiver") {
      fieldsList = fieldsReceivers
    } else if (obj === "ether.send.bank.account") {
      fieldsList = sendAccountFields
    } else if (obj === "ether.office.branch") {
      fieldsList = branchFields
    } else if (obj === "ether.city") {
      fieldsList = cityFields
    } else if (obj === "ether.bank") {
      fieldsList = bankFields
    } else if (obj === "ether.sender.fund.line") {
      fieldsList = fundLineFields
    }
    return fieldsList
  }
  async function SearchRead(obj, addError, { sort, domain, odooRequestOptions } = { sort: "" }) {
    console.debug(`### we are in the beam SearchRead(${obj})`)
    let url = "/web/dataset/search_read"
    let searchReadBody = {
      model: obj,
      domain,
      // limit:100,
      sort: sort,
      fields: getFieldsList(obj)
    }
    return await OdooRequest(url, searchReadBody, addError, odooRequestOptions)
  }

  async function Read(objDetails, addError) {
    console.debug("### we are in the beam Read()")
    let url = `/web/dataset/call_kw/${objDetails.modelName}/read`
    let searchBody = {
      args: [[objDetails.recordId], getFieldsList("ether.trans")],
      method: "read",
      kwargs: {}, // TODO: add timezone
      model: objDetails.modelName
    }
    return await OdooRequest(url, searchBody, addError)
  }

  return {
    SearchRead,
    Read
  }
}
