// assets
import { IconBrandFramer, IconFileText, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd } from '@tabler/icons';

// constant
const icons = {
    IconTerms: IconFileText,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const legal = {
    id: 'legal',
    title: 'Terms & Privacy Policy',
    type: 'group',
    children: [
        {
            id: 'terms',
            title: 'Terms & conditions',
            type: 'item',
            url: '/legal/terms',
            icon: icons['IconTerms'],
            breadcrumbs: true
        },
        {
            id: 'online_terms',
            title: 'Online Terms & conditions',
            type: 'item',
            url: '/legal/online-terms',
            icon: icons['IconTerms'],
            breadcrumbs: true
        },
    ]
};
