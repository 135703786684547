import { useDispatch, useSelector } from "react-redux"
import config from "../utils/config"
import useAPIError from "../hooks/useAPIError"
import * as reduxActions from "./redux"
import { useOdooRead } from "../api/search"
import { useOdooController } from "../api/controller"
import { delay } from "../utils/commonComponentLogic"
import { setCompany, setExecutors, setFundLines, setPurpose, setRelationToReceivers, setSignupInfo, setFundsSource, setNotifications } from "./slices"

const FIVE_SECONDS = 5;
const ONE_HOUR = 3600
const ONE_DAY = 24 * 3600
const DEFAULT_THROTTLE_TIME = 3600
const TERMS_MODEL_NAME = 'all-terms'
const VERSION_MODEL_NAME = 'app-version'
const CONTACT_MODEL_NAME = 'contact-info'
const PROFILE_MODEL_NAME = 'sender-profile'
const COUNTRIES_MODEL_NAME = 'meta-country'
const METADATA_MODEL_NAME = 'meta-metadata'
export const FILES_STATUS_MODEL_NAME = 'files-status'
const NOTIFICATIONS_MODEL_NAME = 'notifications'
const MODELS_TO_THROTTLE = {
  // this should contain the default options for shouldPull()
  'ether.city': {
    time: DEFAULT_THROTTLE_TIME,
  },
  'ether.office.branch': {
    time: ONE_DAY,
  },
  'ether.bank': {
    time: ONE_DAY,
  },
  'ether.trans.purpose': {
    time: FIVE_SECONDS,
  },
  'ether.sender.relations': {
    time: FIVE_SECONDS,
  },
  'ether.payment.source': {
    time: FIVE_SECONDS,
  },
  [TERMS_MODEL_NAME]: {
    time: ONE_DAY,
  },
  [VERSION_MODEL_NAME]: {
    time: ONE_HOUR,
  },
  [CONTACT_MODEL_NAME]: {
    time: DEFAULT_THROTTLE_TIME,
  },
  [PROFILE_MODEL_NAME]: {
    time: FIVE_SECONDS,
  },
  [COUNTRIES_MODEL_NAME]: {
    time: ONE_DAY,
  },
  [FILES_STATUS_MODEL_NAME]: {
    time: FIVE_SECONDS,
  },
  [METADATA_MODEL_NAME]: {
    time: ONE_HOUR,
  },
}

export const usePullData = (commonOptions) => {
  const sentryCapture = commonOptions?.sentryCapture;
  const { addError } = useAPIError()
  const { SearchRead } = useOdooRead(commonOptions)
  const dispatch = useDispatch()
  const odooController = useOdooController(commonOptions)

  const lastUpdate = useSelector((state) => {
    return state.odoo.lastUpdate ? state.odoo.lastUpdate : []
  })
  

  function shouldPull(key){
    // TODO: maybe add an option to force pull, and use it when pulling after we are sure there is a change (e.g on every submit)
    const throttleItem = MODELS_TO_THROTTLE[key]
    let allowDuration = throttleItem?.time ? throttleItem.time : FIVE_SECONDS;  // default any pull to 5 seconds of throttle
    allowDuration *= 1000
    if(lastUpdate && lastUpdate[key]){
      const minTime = Date.now() - allowDuration
      if(lastUpdate[key] > minTime){
        return false;
      }
    }
    return true;
  }
  function _handleThrottleTime(throttleKey){
    dispatch(reduxActions.updateTimeItem({
      name: throttleKey,
      value: Date.now()
    }))
  }

  async function searchReadModel(params) {
    if(!shouldPull(params.modelName)){
      return false;
    }
    const data = await SearchRead(
      params.modelName,
      addError,
      params.searchOptions
    )
    if (data && data.result && data.result.records) {
      const records = params.parseData
        ? params.parseData(data.result.records)
        : data.result.records
      dispatch(params.reduxAction(records))
      const res = records.length && records.length > 0
      if(res){
        _handleThrottleTime(params.modelName)
      }
      return res
    }
    return false
  }

  function parseReceivers(receivers) {
    // middleware for parsing receivers
    const res = []
    receivers.map((receiver) => {
      const viewModel = { ...receiver }
      viewModel.city_name = receiver.city_id[1]
      res.push(viewModel)
    })
    return res
  }

  async function pullProfile(params) {
    if(!shouldPull(PROFILE_MODEL_NAME)){
      return
    }
    const data = await odooController.GetProfile(addError)
    if (data && data.result && data.result.success) {
      const profile = data.result.data
      dispatch(reduxActions.updateProfileAction(profile))
      _handleThrottleTime(PROFILE_MODEL_NAME)
    } else {
      // TODO: handle error here
    }
  }

  async function pullGeneric(pullMethod, reduxAction, options) {
    if(options?.modelName){
      if(!shouldPull(options.modelName)){
        return
      }
    }
    const data = await pullMethod()
    if (data && data.result && data.result.success) {
      const records = data.result.data
      dispatch(reduxAction(records))
      if(options?.modelName){
        _handleThrottleTime(options.modelName)
      }
    } else {
      // TODO: handle error here
    }
  }

  async function pullExecutors() {
    pullGeneric(odooController.GetExecutors, setExecutors)
  }

  async function pullCompany() {
    pullGeneric(odooController.GetCompany, setCompany)
  }

  async function pullTerms() {
    pullGeneric(odooController.GetTerms, reduxActions.updateTermsAction, {modelName: TERMS_MODEL_NAME})
  }

  async function pullVersion() {
    pullGeneric(odooController.GetVersion, reduxActions.updateVersionAction, {modelName: VERSION_MODEL_NAME})
  }

  async function pullNotifications() {
    pullGeneric(odooController.GetNotifications, setNotifications, {modelName: NOTIFICATIONS_MODEL_NAME})
  }

  async function pullCities(params={}) {
    params.reduxAction = reduxActions.updateCitiesAction
    params.searchOptions = {
      sort: "name",
      // should we filter based on the available executors?
      // domain: [["country_id", "in", config.ALLOWED_RECEIVE_COUNTRIES]]
    }
    params.modelName = "ether.city"
    return searchReadModel(params)
  }

  async function pullTrans(params={}) {
    params.reduxAction = reduxActions.updateTransAction
    params.searchOptions = {
      sort: "id desc",
      odooRequestOptions: {
        timeout: 100000,  // give enough time to transactions to update, since it can take a while, but 100 seconds is overshooting it
      }
    }
    params.modelName = "ether.trans"
    return searchReadModel(params)
  }

  async function pullReceivers(params={}) {
    params.reduxAction = reduxActions.updateReceiversAction
    params.modelName = "ether.receiver"
    params.parseData = parseReceivers
    return searchReadModel(params)
  }

  async function pullCountries() {
    pullGeneric(odooController.GetCountries, reduxActions.updateCountriesAction, {modelName: COUNTRIES_MODEL_NAME})
  }

  async function pullMetadata() {
    pullGeneric(odooController.GetMetadata, reduxActions.updateMetadataAction, {modelName: METADATA_MODEL_NAME})
  }

  async function pullSignupInfo() {
    pullGeneric(odooController.GetSignupInfo, setSignupInfo)
  }

  async function pullSendAccounts(params={}) {
    params.reduxAction = reduxActions.updateSendAccountsAction
    params.modelName = "ether.send.bank.account"
    return searchReadModel(params)
  }

  async function pullBranches(params={}) {
    params.reduxAction = reduxActions.updateBranchesAction
    params.modelName = "ether.office.branch"
    return searchReadModel(params)
  }

  async function pullBanks(params={}) {
    params.reduxAction = reduxActions.updateBanksAction
    params.modelName = "ether.bank"
    return searchReadModel(params)
  }

  async function pullFundLines(params={}) {
    params.reduxAction = setFundLines
    params.modelName = "ether.sender.fund.line"
    return searchReadModel(params)
  }

  async function pullController(controllerMethod, dispatchAction, options) {
    // reusable function for all controllers :)
    // DRY: make all above controllers use this
    const data = await controllerMethod(addError)
    if (data && data.result && data.result.success) {
      const version = data.result.data
      dispatch(dispatchAction(version))
      if(options?.modelName){
        _handleThrottleTime(options.modelName)
      }
    } else {
      // TODO: handle error here
    }
  }

  async function pullContact() {
    pullController(odooController.GetContact, reduxActions.updateContact, {modelName: CONTACT_MODEL_NAME})
  }

  async function pullPurposeOfTransaction(params={}) {
    params.reduxAction = setPurpose
    params.modelName = "ether.trans.purpose"
    return searchReadModel(params)
  }

  async function pullRelationToReceiver(params={}) {
    params.reduxAction = setRelationToReceivers
    params.modelName = "ether.sender.relations"
    return searchReadModel(params)
  }

  async function pullFundsSource(params={}) {
    params.reduxAction = setFundsSource
    params.modelName = "ether.fund.source"
    return searchReadModel(params)
  }

  const pullData = async (params={}) => {
    // TODO: set timer to limit number of pulls in a short time, this is important to not overwhelm the server and cause DDoS specially when a component is malfunctioning and is rerendering causing frequent pulls
    try {
      pullProfile({ ...params })
      pullReceivers({ ...params })
      pullTrans({ ...params })
      // await delay(300)  // why? to try an not overwhelm the server :)
      pullExecutors()
      pullCompany()
      pullCountries({ ...params })

      // the next pulls have throttle applied
      // await delay(300)
      pullCities({ ...params })
      pullSendAccounts({ ...params })
      pullBranches({ ...params })
      // await delay(300)
      pullBanks({ ...params })
      pullTerms({ ...params })
      pullVersion({ ...params })
      // await delay(300)
      pullMetadata()
      pullFundLines({ ...params })
    } catch (error) {
      addError("Failed to load data\n" + error) // translate me
      if(sentryCapture){
        sentryCapture(error);
      }
    }
  }

  const clearPersonalData = async () => {
    // maybe checkout this: https://stackoverflow.com/a/35641992/3557761
    dispatch(reduxActions.updateLoginInfoAction({})) // NOTE: the order matters, this needs to be at first, so we indicate to other components and custom hooks on rerendering that the user is no longer logged in
    dispatch(reduxActions.updateTransAction({}))
    dispatch(reduxActions.updateReceiversAction({}))
    dispatch(reduxActions.updateProfileAction({}))
    dispatch(reduxActions.clearTime())  // why? because incase of an issue happened because of incorrect data, the user can logout, then login again, and fetch all data to correct the issue
  }

  return {
    shouldPull,
    _handleThrottleTime,
    pullData,
    pullProfile,
    pullTrans,
    pullReceivers,
    pullExecutors,
    pullCompany,
    pullCities,
    pullCountries,
    pullMetadata,
    pullSignupInfo,
    pullSendAccounts,
    pullBranches,
    pullBanks,
    pullTerms,
    pullVersion,
    pullContact,
    clearPersonalData,
    pullPurposeOfTransaction,
    pullRelationToReceiver,
    pullFundsSource,
    pullFundLines,
    pullNotifications,
  }
}
