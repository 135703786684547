import { useSelector } from "react-redux"
import { selectCompany, selectMetadata } from "../storage/redux"
import useAPIError from "./useAPIError"
import { delay } from "../utils/commonComponentLogic"
import { useState } from "react"

export default function () {
    const { addError, error: globalError } = useAPIError()
    const metadata = useSelector(selectMetadata)
    const company = useSelector(selectCompany)

    // unfinished work
    const retryTimes = Number(company?.fetchRetryTimes || 5) + 1
    const retryDelay = Number(company?.fetchRetryDelay || 200)
    const [lastErrorDate, setLastErrorDate] = useState(new Date())

    const fetchWithTimeout = async (resource, options = {}) => {
        // source: https://dmitripavlutin.com/timeout-fetch-request/
        // author: Dmitri Pavlutin, https://www.linkedin.com/in/dmitri-pavlutin/

        const defaultTimeout = metadata?.settings?.fetch_timeout ? metadata?.settings?.fetch_timeout : 60000  // 20 seconds, sometimes the user is uploading a large file with slow internet? our target users live in countries with high speed internet, that is why we don't need a more timeout

        const { timeout = defaultTimeout } = options

        const controller = new AbortController()
        const id = setTimeout(() => {
            // console.error(`aborted ${resource} ${JSON.stringify(options)}\n after ${timeout} ms`)
            controller.abort()
        }, timeout)
        const response = await fetch(resource, {
            ...options,
            signal: controller.signal  
        })
        clearTimeout(id)
        return response
    }


    async function fetchRetry(resource, fetchOptions) {
        let retryLeft = retryTimes;
        let lastError = undefined
        while (retryLeft > 0){
            try {
                return await fetchWithTimeout(resource, fetchOptions);
            }
            catch (error) { 
                lastError = error
                await delay(retryDelay)
            }
            finally {
                retryLeft -= 1;
            }
        }
        // addError('Please check your internet connection', null, null, { type: "network", header: "Connection Error", caller: 'useFetch.fetchRetry' })
        throw (lastError ? lastError : 'Network request failed')
    }


    const fetchImproved = (resource, options = {}) => {
        return fetchRetry(resource, options)
    }

    return {
        fetchWithTimeout,
        fetchImproved,
    }
}
