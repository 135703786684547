import React, { useEffect } from 'react';
import { actions } from '../../common/providers/APIErrorProvider';
import useAPIError from '../../common/hooks/useAPIError';
import { usePullData } from '../../common/storage';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { useTheme } from '@material-ui/styles';
import { Slide, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText, Button } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function APIErrorDialog() {
  const { clearPersonalData } = usePullData();
  const { error, removeError } = useAPIError();
  const history = useHistory(); 
  const dispatch = useDispatch();

  let header = 'Error'
  let color = 'error.dark'
  const options = error?.options || {}
  if(options.type === 'warn'){
    // primary - for primary interface elements.
    // secondary - for secondary interface elements.
    // error - for elements that the user should be made aware of.
    // warning - for potentially dangerous actions or important messages.
    // info - for highlighting neutral information.
    // success - for indicating the successful completion of an action that the user triggered.
    header = 'Warning'
    color = 'warning.dark'
  }else if(options.type === 'success'){
    header = 'Success'
    color = 'success.dark'
  }else if(!error){
    // this is for when the error is dismissed, and it is animating out of the screen
    color = 'primary.light'
    header = ':)'
  }

  const profile = useSelector(state => {
    return state.odoo.profile ? state.odoo.profile : {};
  });

  useEffect(() => {
    if(error && error.action === actions.sessionInvalid){
      clearPersonalData(dispatch);
    }
  }, [error]);
  useEffect(() => {
    if(profile.force_reset_password == true){
      history.replace('/reset-password');
    }
  }, [profile]);

  const theme = useTheme();

  const titleSx = {
    minWidth: 450,
    bgcolor: color,
    color: `common.white`,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: theme.typography.h3.fontWeight,
    textAlign: 'center'
  }

  const handleClose = () => {
    removeError();
  }

  return (
    <Dialog
      open={error === null ? false : true}
      onClose={handleClose}
      keepMounted
      TransitionComponent={Transition}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle sx={titleSx}>{header}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description" sx={{p:2, color:'text.primary'}}>
          {error && error.message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={{minWidth:100}} variant="outlined" onClick={handleClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
