import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// reducer import
import customizationReducer from './customizationReducer';
import uiStatesReducer from './uiStatesReducer'
import accountReducer from './accountReducer';
import { rootReducer as odooReducer } from '../common/storage/redux';

//-----------------------|| COMBINE REDUCER ||-----------------------//

const rootReducer = combineReducers({
    account: accountReducer,
    customization: customizationReducer,
    uiStates: uiStatesReducer,
    odoo: odooReducer,
});

const reducer = persistReducer(
    {
        key: 'root',
        storage,
        keyPrefix: 'berry-'
    },
    rootReducer
);

export default reducer;
