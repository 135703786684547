import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import Loadable from '../ui-component/Loadable';
import AuthGuard from './../utils/route-guard/AuthGuard';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));
const TransfersList = Loadable(lazy(() => import('../views/transfers')));
const ReceiversList = Loadable(lazy(() => import('../views/receivers')));
const TransferDetails = Loadable(lazy(() => import('../views/transfer-details')));
const ReceiverDetails = Loadable(lazy(() => import('../views/receiver-details')));
const NewTransfer = Loadable(lazy(() => import('../views/new-transfer')));
const NewEditReceiver = Loadable(lazy(() => import('../views/new-edit-receiver')));
const Terms = Loadable(lazy(() => import('../views/legal-terms')));
const OnlineTerms = Loadable(lazy(() => import('../views/legal-online-terms')));
const Profile = Loadable(lazy(() => import('../views/profile')));
const Rates = Loadable(lazy(() => import('../views/rates')));
const SelectExecutor = Loadable(lazy(() => import('../views/select-executor')));
const EditFunds = Loadable(lazy(() => import('../views/edit-funds')));
const FundProofs = Loadable(lazy(() => import('../views/fund-proofs')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')));
const ResetPassword = Loadable(lazy(() => import('../views/reset-password')));

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    const pathsObject = {
        // why this? because I forget to add the url to he Route.path below every time, and I waste a lot of time figuring why the new page is not showing!
        // this object looks stupid, but it is not
        '/transfers': '/transfers',
        '/receivers': '/receivers',
        '/transfer-details': '/transfer-details',
        '/receiver-details': '/receiver-details',
        '/dashboard/default': '/dashboard/default',
        '/new-transfer': '/new-transfer',
        '/new-receiver': '/new-receiver',
        '/edit-receiver': '/edit-receiver',
        '/start-transfer': '/start-transfer',

        '/legal/terms': '/legal/terms',
        '/legal/online-terms': '/legal/online-terms',

        '/profile': '/profile',
        '/rates': '/rates',

        '/utils/util-typography': '/utils/util-typography',
        '/utils/util-color': '/utils/util-color',
        '/utils/util-shadow': '/utils/util-shadow',
        '/icons/tabler-icons': '/icons/tabler-icons',
        '/icons/material-icons': '/icons/material-icons',

        '/sample-page': '/sample-page',
        '/reset-password': '/reset-password',
        '/change-password': '/change-password',
        '/edit-funds': '/edit-funds',
        '/fund-proofs': '/fund-proofs',
    }
    let paths = []
    Object.entries(pathsObject).forEach(([key, value]) => {
        paths.push(key)
    })

    const getPath = (name) => {
        if(name in pathsObject){
            return pathsObject[name]
        }
        throw 'PATH NOT FOUND!!!!!!!!! PLEASE ADD IT TO pathsObject'
    }


    return (
        <Route
            path={paths}
        >
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route path={getPath("/transfers")} component={TransfersList} />
                        <Route path={getPath("/receivers")} component={ReceiversList} />
                        <Route path={getPath("/transfer-details")} component={TransferDetails} />
                        <Route path={getPath("/receiver-details")} component={ReceiverDetails} />
                        <Route path={getPath("/dashboard/default")} component={DashboardDefault} />
                        <Route path={getPath("/start-transfer")} component={SelectExecutor} />
                        <Route path={getPath("/new-transfer")} component={NewTransfer} />
                        <Route path={getPath("/new-receiver")} component={NewEditReceiver} />
                        <Route path={getPath("/edit-receiver")} component={NewEditReceiver} />
                        <Route path={getPath("/fund-proofs")} component={FundProofs} />
                        <Route path={getPath("/edit-funds")} component={EditFunds} />

                        <Route path={getPath("/legal/terms")} component={Terms} />
                        <Route path={getPath("/legal/online-terms")} component={OnlineTerms} />

                        <Route path={getPath("/profile")} component={Profile} />
                        <Route path={getPath("/rates")} component={Rates} />

                        {/* <Route path={getPath("/utils/util-typography")} component={UtilsTypography} />
                        <Route path={getPath("/utils/util-color")} component={UtilsColor} />
                        <Route path={getPath("/utils/util-shadow")} component={UtilsShadow} />
                        <Route path={getPath("/icons/tabler-icons")} component={UtilsTablerIcons} />
                        <Route path={getPath("/icons/material-icons")} component={UtilsMaterialIcons} />
                        <Route path={getPath("/sample-page")} component={SamplePage} /> */}

                        <Route path={getPath("/reset-password")} component={ResetPassword} />
                        <Route path={getPath("/change-password")} component={ResetPassword} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
