const QATAR_COUNTRY_ID = 186
const EGYPT_COUNTRY_ID = 65  // FIXME: the uae country id used here does not have an external id, that is why it will not show in the client, we need to fix this in the backend first
const UAE_COUNTRY_ID = 2  // FIXME: the uae country id used here does not have an external id, that is why it will not show in the client, we need to fix this in the backend first
const SUDAN_COUNTRY_ID = 195

const config = {
  odooDateFormat: "YYYY-MM-DD",
  BANK_OF_KHARTOUM_ID: 1,
  CITY_KHARTOUM_ID: 1,
  imageQuality: 0.8,  // from 0 to 1, 0 means compress for small size and 1 means compress for high quality, see https://docs.expo.dev/versions/latest/sdk/camera/#camerapictureoptions

  SUDAN_COUNTRY_ID,
  QATAR_COUNTRY_ID,
  UAE_COUNTRY_ID,
  EGYPT_COUNTRY_ID,

  ALLOWED_RECEIVE_COUNTRIES: [SUDAN_COUNTRY_ID, QATAR_COUNTRY_ID, UAE_COUNTRY_ID, EGYPT_COUNTRY_ID],
}

export default config

export const fileTypes = {
  id: "id",
  address: "address",
  funds: "funds",
  selfie: "selfie",
  rate_ad: "rate_ad",
  fund_proof: "fund_proof",
}

export const telemetryConsentValues = {
  unknown: 'unknown',
  rejected: 'rejected',
  accepted: 'accepted',
}
