// post processing of fields
function getTransReceiveMethod(state) {
  if (state === "cash") {
    return "Cash"
  }
  if (state === "bank") {
    return "Bank"
  }
  if (state === "mbok") {
    return "mBok"
  }
  if (state === "over_counter") {
    return "Over Counter"
  }
  if (state === "delivery") {
    return "Delivery"
  }
  return "Unknown"
}
export function getTransState(state) {
  if (!state){
    console.error('getTransState is called with !state')
    return ''
  }
  state = state.toLowerCase()
  if (state === "draft") {
    return "Payment Pending"
  }
  if (state === "customer_paid") {
    return "Processing Payment"
  }
  if (state === "paid") {
    return "Payment Confirmed"
  }
  if (["confirm", "approved"].includes(state)) {
    return "To be Received"
  }
  if (state === "received") {
    return "Received"
  }
  if (state === "cancel") {
    return "Canceled"
  }
  return "Unknown"
}
export const transStates = {
  // TODO: use this object instead of getStateStyle
  // state = state.toLowerCase()
  draft: "Payment Pending",
  customer_paid: "Processing Payment",
  paid: "Payment Confirmed",
  confirm: "To be Received",
  approved: "To be Received",
  received: "Received",
  cancel: "Canceled",
}
export const fundStates = {
  draft: 'Draft',
  submit: 'Submitted',
  review: 'Under Review',
  approved: 'Approved',
  expired: 'Expired',
  rejected: 'Rejected',
}
function currencyFormat(num) {
  // will always return a string
  if (!num) {
    return ""
  }
  if (typeof num === "string" || num instanceof String) {
    num = Number(num)
  }
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}
function displayM2O(value) {
  if (value && value.length === 2) {
    return value[1]
  } else {
    return null
  }
}
export { getTransReceiveMethod, currencyFormat, displayM2O }
