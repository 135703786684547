// assets
import { IconUser, IconFile } from '@tabler/icons';

// constant
const icons = {
    IconProfile: IconUser,
    IconFunds: IconFile,
};

//-----------------------|| UTILITIES MENU ITEMS ||-----------------------//

export const profile = {
    id: 'profile',
    title: 'Profile',
    type: 'group',
    children: [
        {
            id: 'profile',
            title: 'Profile',
            type: 'item',
            url: '/profile',
            icon: icons.IconProfile,
            breadcrumbs: true
        },
        {
            id: 'funds',
            title: 'Fund Proofs',
            type: 'item',
            url: '/fund-proofs',
            icon: icons.IconFunds,
            breadcrumbs: true,
            isFundLine: true,
        }
    ]
};
