import React from 'react';

// material-ui
// import { useTheme } from '@material-ui/styles';

import logo from './../assets/images/logo.jpg';


//-----------------------|| LOGO SVG ||-----------------------//

const Logo = () => {
    // const theme = useTheme();  maybe used to know if dark or light!

    return (
        <img src={logo} alt="Ether Services" width="190" />
    );
};

export default Logo;
